<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :retain-focus="false"
    :value="dialog"
    :max-width="380"
    @input="closeModal(false)"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Adicionar novo documento</v-toolbar-title>
          <v-spacer />
          <v-btn
            id="btn-close"
            name="btn-close"
            icon
            @click="closeModal(false)"
          >
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                id="documentType"
                name="documentType"
                label="Tipo de arquivo"
                dense
                outlined
                required
                :rules="[required]"
                :items="documentTypes"
                v-model="model.documentType"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                id="name"
                name="name"
                label="Nome do documento"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.name"
              />
            </v-col>
            <v-col
              cols="12"
              v-if="model.documentType === 'Campanhas e folders'"
            >
              <BaseDatePicker
                id="startCampaignDate"
                name="startCampaignDate"
                label="Data de início"
                required
                :rules="[required]"
                v-model="model.startCampaignDate"
              />
            </v-col>
            <v-col
              cols="12"
              v-if="model.documentType === 'Campanhas e folders'"
            >
              <BaseDatePicker
                id="expirationCampaignDate"
                name="expirationCampaignDate"
                label="Data de expiração"
                required
                :rules="[required]"
                v-model="model.expirationCampaignDate"
              />
            </v-col>
            <v-col cols="12">
              <v-file-input
                id="file"
                name="file"
                label="Arquivo"
                dense
                outlined
                required
                accept=".pdf"
                :rules="[required]"
                v-model="model.file"
              />
            </v-col>
          </v-row>
          <v-divider />
        </v-card-text>
        <v-card-actions class="b-dialog--actions">
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            :disabled="!valid"
            title="Adicionar"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { InsuranceCompanyService } from '@/services/api/insuranceCompany';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    document: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    model: {
      documentType: null,
      documentPath: null,
      file: null,
      name: null,
      startCampaignDate: null,
      expirationCampaignDate: null
    },
    documentTypes: [
      'Proposta comercial',
      'Contrato',
      'Termos e condições gerais',
      'Campanhas e folders'
    ]
  }),

  methods: {
    closeModal(search) {
      this.$emit('closeModal', search);
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status } = await insuranceCompanyService.createDocument({
          insuranceCompanyDocument: this.document,
          ...this.model
        });

        if (status === 204) {
          await insuranceCompanyService.uploadDocument({
            insuranceCompanyDocument: this.document,
            file: this.model.file
          });

          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
